// Left Side-menu (Default dark)
.sidebar-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  @extend .shadow;
  background-color: #283042;
  width: $sidebar-width;
  z-index: 10;
  & ~ .main-layout {
    .navbar-custom {
      .logo-collapsed {
        visibility: hidden;
      }
    }
  }
  &.collapsed {
    width: auto;
    .sidebar-menu-item {
      display: none;
    }
    .badge {
      display: none;
    }
    .logo {
      display: none;
    }
    i {
      margin-right: 0 !important;
    }
    & ~ .main-layout {
      margin-left: 80px;
      .navbar-custom {
        left: 80px;
        .logo-collapsed {
          visibility: visible;
        }
      }
    }
    & ~ .footer {
      margin-left: 80px;
    }
  }
}

.sidebar-top {
  min-height: $topbar-height;
}

// Side-nav
.side-nav {
  padding-left: 0;
  list-style-type: none;
  ul {
    list-style-type: none;
  }
  .side-nav-link {
    display: block;
    padding: 10px 30px;
    font-size: 0.9375rem;
    position: relative;
    transition: all 0.4s;
    list-style: none;
    color: $white;

    &:hover,
    &:focus,
    &.active {
      color: $menu-item-hover;
      text-decoration: none;
      background-color: #455372;
    }

    span {
      vertical-align: middle;
    }

    i {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px 0 0;
      font-size: 1.1rem;
      vertical-align: middle;
      width: 20px;
    }
  }
}

// Wrapper
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

//Content Page
.content-page {
  margin-left: $sidebar-width;
  overflow: hidden;
  padding: $topbar-height 12px 65px;
  min-height: 100vh;
}

/// Help-box
.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
