* {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #495057 !important;
}

.lh-0 {
  line-height: 0;
}

.form-control,
.form-select {
  min-height: 38px;
}
