.col:has(.summary-card):nth-child(1) {
  .card-icon-holder {
    background-color: $blue-100;
    .bi {
      color: $blue;
    }
  }
}

.col:has(.summary-card):nth-child(2) {
  .card-icon-holder {
    background-color: $purple-100;
    .bi {
      color: $purple;
    }
  }
}

.col:has(.summary-card):nth-child(3) {
  .card-icon-holder {
    background-color: $teal-100;
    .bi {
      color: $teal;
    }
  }
}

.col:has(.summary-card):nth-child(4) {
  .card-icon-holder {
    background-color: $cyan-100;
    .bi {
      color: $cyan;
    }
  }
}
