//
// topbar.scss
//

.navbar-custom {
  min-height: $topbar-height;
  left: $sidebar-width;
  z-index: 1001;
}

/* Notification */
.notification-list {
  .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: $topbar-height;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: $danger;
  }

  .notify-item {
    padding: 10px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      b {
        font-weight: 500;
      }
      small {
        display: block;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }
  .topbar-dropdown-menu {
    .notify-item {
      padding: 7px 20px;
    }
  }
}

.profile-dropdown {
  min-width: 170px;

  i,
  span {
    vertical-align: middle;
  }
}

.nav-user {
  text-align: left !important;
  position: relative;
  background-color: lighten($gray-100, 2.5%);
  border: 1px solid $gray-100;
  border-width: 0 1px;
  min-height: $topbar-height;

  .account-user-avatar {
    img {
      height: 32px;
      width: 32px;
    }
  }
  .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px;
  }
  .account-user-name {
    display: block;
    font-weight: $font-weight-semibold;
  }
}
